<template>
    <BackgroundBase>
        <div class="gst-background-blob--one"></div>
        <div class="gst-background-blob--two"></div>
        <BackgroundStripes class="gst-booking-info__background-stripes" />
        <BackgroundGradient />
        <BackgroundGradient position="bottom" />
    </BackgroundBase>
</template>

<script>
    import BackgroundBase from '@tenant/app/components/background/BackgroundBase';
    import BackgroundStripes from '@tenant/app/components/background/BackgroundStripes';
    import BackgroundGradient from '@tenant/app/components/background/BackgroundGradient';

    export default {
        name: 'BookingInfoAnimation',
        components: {
            BackgroundBase,
            BackgroundGradient,
            BackgroundStripes
        }
    };
</script>

<style lang="scss" scoped>
@import "@scssVariables";
@import "@scssMixins";

$animation-duration: 10s;

.gst-booking-info__background-stripes {
    top: -1000px !important;
    left: -1000px !important;
    right: -1000px !important;
    bottom: -1000px !important;
    transform: rotate( -60deg );
}

.gst-background-blob--one {
    position: absolute;
    top: 57px;
    left: 50%;
    height: 533px;
    width: 829px;
    background-color: #024DDF;
    border-radius: 36% 64% 31% 69% / 54% 77% 23% 46%;
    opacity: 0.65;
    filter: blur( 200px );
    animation: move $animation-duration ease-in-out infinite both alternate;
}

.gst-background-blob--two {
    position: absolute;
    top: 106px;
    right: 50%;
    height: 372px;
    width: 786px;
    background-color: #A733FF;
    border-radius: 36% 64% 31% 69% / 54% 77% 23% 46%;
    opacity: 0.6;
    filter: blur( 200px );
    animation: move-reverse $animation-duration ease-in-out infinite both alternate;
}

@keyframes change-shape {
    0%,
    100% { border-radius: 33% 67% 70% 30% / 30% 30% 70% 70%; }
    20% { border-radius: 37% 80% 51% 49% / 37% 65% 35% 63%; }
    40% { border-radius: 36% 64% 64% 36% / 64% 48% 52% 36%; }
    60% { border-radius: 37% 63% 51% 49% / 20% 35% 70% 70%; }
    80% { border-radius: 40% 50% 42% 58% / 41% 61% 49% 59%; }
}

@keyframes move {
    0% { transform: translate( 0, 0 ) scale( 1 ); }
    33% { transform: translate( 20%, 20% ) scale( 1.1 ); }
    66% { transform: translate( 40%, 20% ) scale( 1.2 ); }
    100% { transform: translate( 60%, 0 ) scale( 1.3 ); }
}

@keyframes move-reverse {
    0% { transform: translate( 0, 0 ) scale( 1 ); }
    33% { transform: translate( -20%, 20% ) scale( 1.1 ); }
    66% { transform: translate( -40%, 20% ) scale( 1.2 ); }
    100% { transform: translate( -60%, 0 ) scale( 1.3 ); }
}
</style>