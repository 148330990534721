<template>
    <div class="gst-picture-base">
        <BaseImage
            :src="src"
            :alt="alt" />
    </div>
</template>

<script>
    import BaseImage from '@core/shared/components/misc/BaseImage.vue';

    export default {
        name: 'PictureBase',
        components: {
            BaseImage,
        },
        props: {
            src: {
                type: String,
                default: '',
            },
            alt: {
                type: String,
                default: '',
            },
        },
    };
</script>