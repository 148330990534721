<template>
    <BackgroundBase>
        <div class="gst-background-blob--one"></div>
        <div class="gst-background-blob--two"></div>
        <div class="gst-background-blob--three"></div>
        <div class="gst-background-blob--four"></div>
        <div class="gst-background-blob--five"></div>
        <BackgroundStripes />
        <BackgroundGradient />
        <BackgroundGradient position="bottom" />
    </BackgroundBase>
</template>

<script>
    import BackgroundBase from '@tenant/app/components/background/BackgroundBase';
    import BackgroundStripes from '@tenant/app/components/background/BackgroundStripes';
    import BackgroundGradient from '@tenant/app/components/background/BackgroundGradient';

    export default {
        name: 'TopOffersAnimation',
        components: {
            BackgroundBase,
            BackgroundGradient,
            BackgroundStripes
        }
    };
</script>

<style lang="scss" scoped>
@import "@scssVariables";
@import "@scssMixins";

$animation-duration: 15s;

.gst-background-blob--one {
    position: absolute;
    height: 730px;
    width: 1016px;
    background-color: #FF134C;
    border-radius: 36% 64% 31% 69% / 54% 77% 23% 46%;
    opacity: 0.7;
    filter: blur( 135px );
    animation: spotlight1 $animation-duration linear infinite alternate;
}

@keyframes spotlight1 {
    0% {
        top: 16px;
        right: 50%;
        transform: translateX( 30% ) scale( 1 );
    }

    20% {
        top: 113px;
        right: 50%;
        transform: translateX( 5% ) scale( 0.8 );
    }

    40% {
        top: 279px;
        right: 50%;
        transform: translateX( 10% ) scale( 0.8 );
    }

    60% {
        top: 463px;
        right: 50%;
        transform: translateX( 5% ) scale( 0.73 );
    }

    80% {
        top: 569px;
        right: 50%;
        transform: translateX( -10% ) scale( 0.92 );
    }

    100% {
        top: 374px;
        right: 50%;
        transform: translateX( -15% ) scale( 0.92 ) rotate( -60deg );
    }
}

.gst-background-blob--two {
    position: absolute;
    height: 558px;
    width: 777px;
    background-color: #DD6F40;
    border-radius: 36% 64% 31% 69% / 54% 77% 23% 46%;
    opacity: 0.7;
    filter: blur( 135px );
    animation: spotlight2 $animation-duration linear infinite alternate;
}

@keyframes spotlight2 {
    0% {
        top: 6px;
        left: 45%;
        transform: scale( 1 );
    }

    20% {
        top: 6px;
        left: 50%;
        transform: scale( 1 );
    }

    40% {
        top: -144px;
        left: 50%;
        transform: translateX( -30% ) scale( 0.97 ) rotate( -30deg );
    }

    60% {
        top: 0;
        left: 50%;
        transform: translateX( -30% ) scale( 0.83 ) rotate( -30deg );
    }

    80% {
        top: -19px;
        left: 50%;
        transform: translateX( -60% ) scale( 0.93 ) rotate( -135deg );
    }

    100% {
        top: 412px;
        left: 50%;
        transform: translateX( -60% ) scale( 0.93 ) rotate( -135deg );
    }
}

.gst-background-blob--three {
    position: absolute;
    height: 566px;
    width: 560px;
    background-color: #FF134C;
    border-radius: 36% 64% 31% 69% / 54% 77% 23% 46%;
    opacity: 0.5;
    filter: blur( 135px );
    animation: spotlight3 $animation-duration linear infinite alternate;
}

@keyframes spotlight3 {
    0% {
        top: 500px;
        left: 50%;
        transform: scale( 1 );
    }

    20% {
        top: 385px;
        left: 50%;
        transform: translateX( -10% ) scale( 1.2 );
    }

    40% {
        top: 575px;
        left: 50%;
        transform: translateX( -30% ) scale( 1.2 );
    }

    60% {
        top: 773px;
        left: 50%;
        transform: translateX( -70% ) scale( 0.95 );
    }

    80% {
        top: 569px;
        left: 50%;
        transform: translateX( -30% ) scale( 0.95 );
    }

    100% {
        top: 412px;
        left: 50%;
        transform: translateX( -100% ) scale( 0.95 );
    }
}

.gst-background-blob--four {
    position: absolute;
    height: 352px;
    width: 490px;
    background-color: #404FDD;
    border-radius: 36% 64% 31% 69% / 54% 77% 23% 46%;
    opacity: 0.6;
    filter: blur( 135px );
    animation: spotlight4 $animation-duration linear infinite alternate;
}

@keyframes spotlight4 {
    0% {
        top: 714px;
        right: 50%;
        transform: translateX( -50% ) scale( 1 );
    }

    20% {
        top: 519px;
        right: 50%;
        transform: translateX( -10% ) scale( 1.25 );
    }

    40% {
        top: 195px;
        right: 50%;
        transform: translateX( -10% ) scale( 1.25 ) rotate( 45deg );
    }

    60% {
        top: 97px;
        right: 50%;
        transform: translateX( -20% ) scale( 1.12 ) rotate( 45deg );
    }

    80% {
        top: 97px;
        right: 50%;
        transform: translateX( -20% ) scale( 1.12 ) rotate( 45deg );
    }

    100% {
        top: -10px;
        right: 50%;
        transform: translateX( 0% ) scale( 1.25 ) rotate( 45deg );
    }
}

.gst-background-blob--five {
    position: absolute;
    height: 396px;
    width: 610px;
    background-color: #404FDD;
    border-radius: 36% 64% 31% 69% / 54% 77% 23% 46%;
    opacity: 0.7;
    filter: blur( 135px );
    animation: spotlight5 $animation-duration linear infinite alternate;
}

@keyframes spotlight5 {
    0% {
        top: 660px;
        left: 50%;
        transform: translateX( 20% ) scale( 1 );
    }

    20% {
        top: 660px;
        left: 50%;
        transform: translateX( 20% ) scale( 1 );
    }

    40% {
        top: 575px;
        left: 50%;
        transform: translateX( 25% ) scale( 1 );
    }

    60% {
        top: 492px;
        left: 50%;
        transform: translateX( 10% ) scale( 1.13 ) rotate( -80deg );
    }

    80% {
        top: 463px;
        left: 50%;
        transform: translateX( -30% ) scale( 1.13 ) rotate( -45deg );
    }

    100% {
        top: 283px;
        left: 50%;
        transform: translateX( 10% ) scale( 1.13 ) rotate( 65deg );
    }
}
</style>