<template>
    <div ref="searchWrapper" class="gst-search-hotels d-flex" tabindex="0" @click="throttleTooltipWarningSearchClose" @keypress.enter="throttleTooltipWarningSearchClose">
        <LocationSearchStoreDropDown
            ref="location"
            :value="location"
            class="flex-grow-1"
            no-border
            :nudge-bottom="15"
            @input="onLocationChangeDo" />
        <v-tooltip
            v-model="tooltipWarningSearch.show"
            top
            left
            :position-x="tooltipWarningSearch.positionX"
            :position-y="tooltipWarningSearch.positionY"
            content-class="gst-search-hotels__tooltip-warning-search">
            <span>{{ $t('messages.searchWarning') }}</span>
        </v-tooltip>
        <SearchDateDropdown
            ref="searchDate"
            v-model="date"
            :min="minBookingStartDate"
            :allow-same-day-range="false"
            color="#024DDF"
            calendar-color="#024DDF"
            class="gst-search-hotels__dates"
            @tab-keydown="onSearchDateDropdownTabKeyDownDo" />
        <SearchButton class="gst-search-hotels__submit" type="submit" @click.native="submit" />
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    import throttle from 'lodash/throttle';
    import SearchButton from '@core/shared/components/buttons/SearchButton.vue';
    import SearchDateDropdown from '@core/shared/components/search/SearchDateDropdown.vue';
    import LocationSearchStoreDropDown from '@tenant/app/components/misc/LocationSearchStoreDropDown/LocationSearchStoreDropDown';
    import { formatQueryParam as dateUtilsFormatQueryParam } from '@tenant/app/utils/dateUtils';
    import { minCheckInDate } from '@tenants/ticketmaster/app/utils/bookingUtils';
    import { addDays } from '@tenants/ticketmaster/app/utils/dateUtils';

    export default {
        name: 'SearchHotelsInput',
        components: {
            SearchButton,
            SearchDateDropdown,
            LocationSearchStoreDropDown
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.home.searchProducts.hotels.search'
        },
        data() {
            return {
                location: {},
                date: {
                    startDate: null,
                    endDate: null
                },
                tooltipWarningSearch: {
                    show: false,
                    positionX: 0,
                    positionY: 0
                }
            };
        },
        computed: {
            ...mapGetters( {
                tokenStatus: `appState/tokenStatus`
            } ),
            minBookingStartDate( ) {
                return minCheckInDate( null, this.tokenStatus );
            }
        },
        watch: {
            'date': {
                handler: function ( { startDate, endDate } ) {
                    if ( startDate && !endDate ) {
                        this.date.endDate = addDays( startDate, 1 );
                    }
                },
                deep: true
            }
        },
        methods: {
            onLocationChangeDo( newLocation ) {
                this.location = { ...newLocation };
            },
            submit( event ) {
                event.preventDefault( );
                event.stopPropagation( );

                if ( !this.location?.name ) {
                    const pos = this.$refs.searchWrapper.getBoundingClientRect( );

                    this.tooltipWarningSearch.positionX = pos.x + 135;
                    this.tooltipWarningSearch.positionY = pos.y + pos.height + 55;
                    this.tooltipWarningSearch.show = true;
                } else {
                    const queryParams = { ...this.location };
                    if ( this.date.startDate ) {
                        queryParams.startDate = dateUtilsFormatQueryParam( this.date.startDate );
                    }
                    if ( this.date.endDate ) {
                        queryParams.endDate = dateUtilsFormatQueryParam( this.date.endDate );
                    }
                    const newRoute = this.$router.resolve( { name: 'hotels', query: { ...queryParams } } );
                    window.open( newRoute.href, '_blank' );
                }
            },
            throttleTooltipWarningSearchClose: throttle( function ( ) {
                this.tooltipWarningSearch.show = false;
            }, 20 ),
            isFormSubmitOnEnter( event ) {
                return event.offsetX <= 0 && event.offsetY <= 0;
            },
            onSearchDateDropdownTabKeyDownDo( ) {
                setTimeout( () => {
                    this.$refs.searchDate.$refs.inputSearch.$refs.input.blur();
                    this.$refs.searchDate.closeMenu();
                }, 50 );
            }
        },
        mounted( ) {
            window.addEventListener( 'resize', this.throttleTooltipWarningSearchClose );
        },
        destroyed( ) {
            window.removeEventListener( 'resize', this.throttleTooltipWarningSearchClose );
        }
    };
</script>
<style lang="scss" scoped>
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-search-hotels {
        height: 100%;
        width: 100%;
        gap: 24px;

        .gst-search-hotels__tooltip-warning-search {
            width: 300px;
            background-color: theme-color( 'white' ) !important;
            color: theme-color( 'error' );
            max-width: 300px;
            opacity: 1 !important;
        }

        .gst-search-hotels__submit {
            height: auto;
            background-color: theme-color( 'primary' ) !important;
            color: theme-color( 'white' ) !important;
            text-transform: none !important;
        }
    }

    .gst-search-hotels--overlay {
        position: relative;
        font-size: font-size( 'xxs' );
        font-weight: font-weight( 'regular' );
        z-index: $z-index-header + 2;
    }

</style>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-search-date__picker {
        margin-top: theme-spacing( 4 ) !important;
        border-radius: border-radius( 'xxs' ) !important;
    }

    .gst-search-hotels .gst-search-icon-svg-focused {
        .gst-svg-icon {
            fill: theme-color( 'primary' );
        }
    }
</style>
