<template>
    <section class="gst-offer-types">
        <div class="gst-offer-types__content gst-default-content">
            <div class="gst-offer-types__list">
                <div
                    v-for="( item, key ) in slice.items"
                    :key="key"
                    class="gst-offer-types__list__item">
                    <PrismicRichText :field="item.offer_type" />
                    <BaseIcon symbol-id="icons--check" />
                </div>
            </div>
            <PrismicRichText 
                :field="slice.primary.description"
                class="gst-offer-types__text" />
        </div>
    </section>
</template>

<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'OfferTypes',
        components: {
            BaseIcon
        },
        props: {
            slice: {
                type: Object,
                required: true
            },
            context: {
                type: Object,
                required: true
            }
        }
    };
</script>
