<template>
    <div class="gst-image-scroll-gallery">
        <div class="gst-image-scroll-gallery__container" :style="$vuetify.breakpoint.mdAndUp ? section: sectionMobile">
            <div class="gst-image-scroll-gallery__section">
                <BaseImage v-for="item in items" :key="item.id" :src="item.image.url" />
            </div>
            <div class="gst-image-scroll-gallery__section">
                <BaseImage v-for="item in items" :key="item.id" :src="item.image.url" />
            </div>
        </div>
    </div>
</template>

<script>
    import BaseImage from '@core/shared/components/misc/BaseImage.vue';

    export default {
        name: 'ImageScrollGallery',
        components: {
            BaseImage
        },
        props: {
            items: {
                type: Array,
                required: true
            },
            reversed: {
                type: Boolean,
                default: false
            },
            animationTime: {
                type: Number,
                default: 60
            }
        },
        data() {
            return {
                imageWidth: 772,
                imageHeight: 400,
                imageGap: 16,
                imageWidthMobile: 200,
                imageHeightMobile: 150,
                imageGapMobile: 12
            };
        },
        computed: {
            width() {
                // Calculates container width based on the number of elements plus element size
                // This allows the scroll to show the infinite movement without jumps in the animation
                return this.items.length * this.imageWidth + ( this.items.length * this.imageGap );
            },
            widthMobile() {
                // Calculates container width based on the number of elements plus element size
                return this.items.length * this.imageWidthMobile + ( this.items.length * this.imageGapMobile );
            },
            section( ) {
                return `animation: move ${this.animationTime}s linear infinite; width: ${this.width}px; gap: ${this.imageGap}px; animation-direction: ${this.reversed ? 'reverse': 'normal'}`;
            },
            sectionMobile() {
                return `animation: move ${this.animationTime}s linear infinite; width: ${this.widthMobile}px; gap: ${this.imageGapMobile}px; animation-direction: ${this.reversed ? 'reverse': 'normal'}`;
            }
        }
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

@keyframes move {
    0% {
        transform: translateX( 0 );
    }

    100% {
        transform: translateX( -100% );
    }
}

</style>