<template>
    <v-menu
        ref="menu"
        :value="isMenuOpen"
        offset-y
        :close-on-content-click="true"
        bottom
        :nudge-bottom="20"
        :max-height="400"
        :max-width="600"
        content-class="gst-search-events-input__menu rounded-sm"
        @input="isMenuOpen=$event">
        <template v-slot:activator="{on}">
            <div
                class="gst-search-events-input flex-grow-1 d-flex align-center"
                :class="{
                    'gst-search-events-input--focus': isFocus
                }"
                v-on="on">
                <BaseIcon symbol-id="icons--search" class="icon-search" />
                
                <BaseTooltip :text="$t('placeholder')">
                    <BaseInput
                        v-model="keyword"
                        :data-aria-label="$t('placeholder')"
                        data-test-id="search.events"
                        :placeholder="$t('placeholder')"
                        @focus="isFocus=true"
                        @blur="isFocus=false" />
                </BaseTooltip>

                <BaseButton v-if="keyword" icon small text @click="clear">
                    <BaseIcon symbol-id="icons--cancel" class="gst-search-icon-cancel-svg" />
                </BaseButton>
            </div>
        </template>
        <div class="bg-white">
            <SearchEventsList
                :redirect-to-attraction="false"
                :redirect-to-venue="false"
                @select="selectItem" />
        </div>
    </v-menu>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import BaseInput from '@core/shared/components/input/BaseInput.vue';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import BaseTooltip from '@core/shared/components/tooltips/BaseTooltip.vue';
    import SearchEventsList from './SearchEventsList';

    export default {
        name: 'SearchEventsInput',
        components: {
            BaseInput,
            SearchEventsList,
            BaseButton,
            BaseIcon,
            BaseTooltip
        },
        data( ) {
            return {
                isMenuOpen: false,
                isFocus: false
            };
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.home.searchProducts.events.search'
        },
        computed: {
            ...mapGetters( {
                loading: 'searchEvents/loading',
                isEmptyKeywordFilter: 'searchEvents/isEmptyKeywordFilter',
            } ),
            keyword: {
                get( ) {
                    return this.$store.state.searchEvents.filter.keyword;
                },
                set( value ) {
                    this.updateSearch( { keyword : value } );
                }
            },
            isMobile( ) {
                return this.$vuetify.breakpoint.smAndDown;
            },
        },
        watch: {
            'isEmptyKeywordFilter': function ( value ) {
                if ( value ) {
                    this.isMenuOpen = false;
                    this.$refs.menu.isActive = false;
                }
            },
            'isMenuOpen': {
                handler: function( value ) {
                    if( value && this.isEmptyKeywordFilter ) {
                        this.isMenuOpen = false;
                        this.$refs.menu.isActive = false;
                    }
                }
            },
            'loading': {
                handler: function( value ) {
                    if ( value ) {
                        this.isMenuOpen = true;
                    }
                }
            }
        },
        methods: {
            ...mapActions( {
                updateSearch: 'searchEvents/update',
                search: 'searchEvents/search',
                clearAll: 'searchEvents/clear',
            } ),
            selectItem( value ) {
                this.updateSearch( { keyword: value } );
            },
            clear( ) {
                this.updateSearch( { keyword: '' } );
            },
        },
        created( ) {
            this.clearAll( );
        }
    };
</script>