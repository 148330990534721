<template>
    <v-card v-bind="$attrs" :max-width="maxWidth" class="gst-card-base rounded-1 d-flex flex-column ">
        <slot name="cardImage"></slot>

        <slot name="header"></slot>

        <div class="body">
            <slot name="body"></slot>
        </div>

        <slot name="footer"></slot>
        <!-- Default while static -->
        <slot></slot>
    </v-card>
</template>

<script>
    export default {
        name: 'CardBase',
        props: {
            maxWidth: {
                type: String,
                default: '400'
            }
        }
    };
</script>
