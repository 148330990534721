<template>
    <header class="gst-header">
        <div>
            <TheLogo />
        </div>
        <div v-if="!$vuetify.breakpoint.mdAndUp" d-flex align-center shrink justify-center class="pl-2">
            <v-btn icon @click="openMenu">
                <img src="@tenant/app/assets/icons/burger.png" alt="ticketmaster" />
            </v-btn>
        </div>
        <div v-else class="gst-header__links-wrapper d-flex ml-auto">
            <router-link class="d-flex" :to="routeEventsTab">
                <BaseIcon symbol-id="icons--search" class="mr-2" /> {{ $t('linkSearch') }}
            </router-link>
            <router-link :to="{ name: 'home', hash: '#latest-deals'}">
                {{ $t('linkLatestDeals') }}
            </router-link>
            <router-link :to="{ name: 'home', hash: '#faq'}">
                {{ $t('linkFaq') }}
            </router-link>
            <LanguageSelectField class="ml-6" />
        </div>
    </header>
</template>

<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import SearchConstants from '@tenant/app/utils/constants/search';
    import TheLogo from '@tenants/ticketmaster/app/layouts/components/TheLogo.vue';
    import LanguageSelectField from '@tenant/app/components/inputs/LanguageSelectField.vue';


    export default {
        name: 'TheHeader',
        i18nOptions:{
            namespaces: 'main',
            keyPrefix: 'views.home.theHeader'
        },
        components: {
            BaseIcon,
            TheLogo,
            LanguageSelectField
        },
        data() {
            return {
                menuExpanded: false
            };
        },
        computed: {
            routeEventsTab( ) {
                return {
                    name: 'home',
                    query: { searchType: SearchConstants.TYPES.EVENTS },
                    hash: '#search-products'
                };
            }
        },
        methods: {
            openMenu() {
                this.$emit( 'menu-opened' );
            }
        }
    };
</script>