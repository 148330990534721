<template>
    <div class="gst-background-stripes d-flex flex-column">
        <div class="gst-background-stripes__top flex-grow-0 flex-shrink-0"></div>
        <div class="gst-background-stripes__center flex-grow-1"></div>
        <div class="gst-background-stripes__bottom flex-grow-0 flex-shrink-0"></div>
    </div>
</template>

<script>
    export default {
        name: 'BackgroundStripes'
    };
</script>

<style lang="scss" scoped>
@import "@scssVariables";
@import "@scssMixins";

.gst-background-stripes {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.gst-background-stripes__center {
    background: url( '~@tenant/app/assets/background-stripes.png' );
}

.gst-background-stripes__top {
    height: 373px;
    background: url( '~@tenant/app/assets/background-stripes-top.png' );
}

.gst-background-stripes__bottom {
    height: 373px;
    background: url( '~@tenant/app/assets/background-stripes-bottom.png' );
}
</style>
