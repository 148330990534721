import { render, staticRenderFns } from "./TopOffers.vue?vue&type=template&id=a272a006&scoped=true&"
import script from "./TopOffers.vue?vue&type=script&lang=js&"
export * from "./TopOffers.vue?vue&type=script&lang=js&"
import style0 from "./TopOffers.vue?vue&type=style&index=0&id=a272a006&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a272a006",
  null
  
)

export default component.exports