<template>
    <v-row class="mr-0 ml-0">
        <v-col 
            v-for="(n, key) in items.length"
            :key="key"
            :cols="numberOfCols"
            :class="$vuetify.breakpoint.lgAndUp ? 'gst-row--col__translate': ''">
            <CardBase
                :max-width="$vuetify.breakpoint.lgAndUp ? '400' : '343'">
                <template>
                    <a v-if="items[key].cta_link.url" :href="items[key].cta_link.url" :target="items[key].cta_link.target">
                        <PictureBase :src="$vuetify.breakpoint.lgAndUp ? items[key].imagedesktop.url || '' : items[key].imagemobile.url || ''" :alt="items[key].imagedesktop.url || ''" />
                    </a>
                    <PictureBase v-else :src="$vuetify.breakpoint.lgAndUp ? items[key].imagedesktop.url || '' : items[key].imagemobile.url || ''" :alt="items[key].imagedesktop.url || ''" />
                </template>
            </CardBase>
        </v-col>
    </v-row>
</template>

<script>
    import PictureBase from './PictureBase.vue';
    import CardBase from './CardBase.vue';


    export default {
        name: 'PictureCardsGridBase',
        components: {
            PictureBase,
            CardBase,
        },
        props: {
            items: {
                type: Array,
                default: () => [],
            },
            numberOfCols: {
                type: String,
                default: ''
            },
        }
    };
</script>
