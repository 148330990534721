<template>
    <v-btn
        center
        text
        outlined
        class="text-capitalize gst-search-all-btn"
        color="primary"
        v-bind="$attrs"
        @click="$emit('click')">
        {{ $t('shared:components.buttons.seeAll') }}
    </v-btn>
</template>
<script>
    export default {
        name: 'SeeAllButton',
        inheritsAttribute: true,
        i18nOptions: {
            namespaces: 'shared',
            keyPrefix: 'components.buttons'
        }
    };
</script>

<style lang="scss" scoped>
    @import "@scssMixins";
    @import "@scssVariables";

    .gst-search-all-btn.v-btn.v-btn--outlined {
        padding: 0 60px;
        color: theme-color( 'new-primary' ) !important;
        font-weight: font-weight( 'large' );
        border-radius: border-radius( 'xs' ) !important;
        min-height: 36px;

        &.v-btn--disabled {
            color: theme-color( 'new-primary' ) !important;
            border-color: theme-color( 'new-primary' );
            opacity: 0.4;
        }
    }

    .gst-search-all-btn.v-btn.v-btn--outlined:not([disabled]) {
        border-color: theme-color( 'new-primary' );
    }
</style>
