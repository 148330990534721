<template>
    <form
        ref="searchKeywordAutocomplete"
        class="gst-search-events d-flex"
        tabindex="0"
        @click="throttleTooltipWarningSearchClose"
        @keypress.esc="throttleTooltipWarningSearchClose">
        <div class="d-flex flex-grow-1">
            <v-col cols="6" class="d-flex pa-0">
                <SearchEventsInput />
                <v-tooltip
                    v-model="tooltipWarningSearch.show"
                    top
                    left
                    :position-x="tooltipWarningSearch.positionX"
                    :position-y="tooltipWarningSearch.positionY"
                    content-class="gst-search-field-banner__tooltip-warning-search">
                    <span>{{ $t('_common:messages.searchWarning') }}</span>
                </v-tooltip>
            </v-col>
            <v-col cols="6" class="pa-0">
                <SearchCity
                    ref="searchCity"
                    v-model="city"
                    class="gst-search-field-banner__search-city" />
            </v-col>
        </div>
        <SearchButton class="gst-search-events__submit flex-grow-0 flex-shrink-0" type="submit" @click.native="submit" />
    </form>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import throttle from 'lodash/throttle';
    import SearchButton from '@core/shared/components/buttons/SearchButton.vue';
    import SearchCity from '@tenant/app/components/search/SearchCity.vue';
    import SearchEventsInput from './SearchEvents/SearchEventsInput';

    export default {
        name: 'SearchEvents',
        components: {
            SearchButton,
            SearchEventsInput,
            SearchCity
        },
        data() {
            return {
                tooltipWarningSearch: {
                    show: false,
                    positionX: 0,
                    positionY: 0
                }
            };
        },
        computed: {
            ...mapGetters( {
                currentQueryObject: 'searchEvents/currentQueryObject',
                isOnlyKeyword: 'searchEvents/isOnlyKeyword',
                isEmptyFilter: 'searchEvents/isEmptyFilter'
            } ),
            city: {
                get( ) {
                    return this.$store.state.searchEvents.filter.city;
                },
                set( value ) {
                    this.updateSearch( { city : value } );
                }
            },
        },
        watch: {
            'currentQueryObject': function ( ) {
                this.tooltipWarningSearch.show = false;
            }
        },
        methods: {
            ...mapActions( {
                updateSearch: 'searchEvents/update',
            } ),
            submit( event ) {
                event.preventDefault( );
                event.stopPropagation( );

                const isEnter = this.isFormSubmitOnEnter( event );

                if ( this.isEmptyFilter ) {

                    const pos = this.$refs.searchKeywordAutocomplete.getBoundingClientRect( );

                    this.tooltipWarningSearch.positionX = pos.x + 135;
                    this.tooltipWarningSearch.positionY = pos.y + pos.height + 60;
                    this.tooltipWarningSearch.show = true;
                } else {
                    let route;
                    if ( isEnter || ( !isEnter && this.isOnlyKeyword ) ) {
                        route = this.$router.resolve( { name: 'search', query: this.currentQueryObject } );
                    } else {
                        route = this.$router.resolve( { name: 'search.events', query: this.currentQueryObject } );
                    }
                    window.open( route.href, '_blank' );
                }
            },
            throttleTooltipWarningSearchClose: throttle( function ( ) {
                this.tooltipWarningSearch.show = false;
            }, 20 ),
            isFormSubmitOnEnter( event ) {
                return event.offsetX <= 0 && event.offsetY <= 0;
            },
        },
        mounted( ) {
            window.addEventListener( 'resize', this.throttleTooltipWarningSearchClose );
        },
        destroyed( ) {
            window.removeEventListener( 'resize', this.throttleTooltipWarningSearchClose );
        }
    };
</script>

<style lang="scss" scoped>
@import "@scssVariables";
@import "@scssMixins";

.gst-search-events {
    height: 100%;
    width: 100%;
    gap: 24px;

    .gst-search-field-banner__tooltip-warning-search {
        width: 300px;
        background-color: theme-color( 'white' ) !important;
        color: theme-color( 'error' );
        border-radius: border-radius( 'xs' ) !important;
        max-width: 300px;
        opacity: 1 !important;
    }
}

.gst-search-events__submit {
    height: auto !important;
    background-color: theme-color( 'primary' ) !important;
    color: theme-color( 'white' ) !important;
    text-transform: none !important;
}
</style>