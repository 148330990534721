import SearchConstants from '@tenant/app/utils/constants/search';

export default {
    i18nOptions: {
        namespaces: 'main',
        keyPrefix: 'views.home.searchProducts'
    },
    data( ) {
        return {
            activeTab: 0,
        };
    },
    computed: {
        tabs() {
            return  [
                {
                    id: SearchConstants.TYPES.EVENTS,
                    title: this.$t( 'events.tab' ),
                    icon: 'icons--ticket_bed'
                },
                {
                    id: SearchConstants.TYPES.HOTELS,
                    title: this.$t( 'hotels.tab' ),
                    icon: 'icons--bed'
                }
            ];
        },
        activeType( ) {
            return this.tabs[ this.activeTab ].id;
        },
        isEventTabActive( ) {
            return this.activeType === SearchConstants.TYPES.EVENTS;
        },
        isHotelTabActive( ) {
            return this.activeType === SearchConstants.TYPES.HOTELS;
        },
    }
};