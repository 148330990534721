<template>
    <v-menu
        v-model="isMenuOpen"
        :position-x="menuTargetPositionX"
        :position-y="menuTargetPositionY"
        :close-on-content-click="false"
        bottom
        absolute
        allow-overflow
        :nudge-bottom="20"
        content-class="gst-search-city-menu rounded-sm">
        <template v-slot:activator="{ on }">
            <v-text-field
                ref="inputSearch"
                :value="isFocused ? searchValue : ''"
                :placeholder="!isFocused ? selectValueLabel : ''"
                class="mx-1 pt-0 mr-3 gst-search-input gst-search-city-menu__input-search"
                v-bind="inputSearchAttrs"
                autocomplete="off"
                data-hj-allow
                @click="toggleMenu"
                @input="onInputInputSearch"
                @blur="onBlurInputSearch"
                @focus="onFocusInputSearch"
                @keydown.tab="onTabKeyDo">
                <template slot="prepend-inner" class="text-primary">
                    <BaseIcon class="gst-search-icon-svg"
                        :class="{
                            'gst-search-icon-svg-focused': isFocused
                        }"
                        symbol-id="icons--location2"
                        @click="toggleMenu" />
                </template>
                <template v-if="!isFocused" slot="label">
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <span class="gst-search-label" v-html="$options.filters.cityHTML( value, { country: true } )"></span>
                </template>
                <template slot="append" class="text-primary">
                    <data-loading v-if="loadingCurrentLocation" :width="2" :size="20" class="d-flex align-self-center justify-center" />
                    <BaseIcon v-if="searchValue && clearable"
                        class="gst-search-icon-cancel-svg u-mouse-pointer"
                        symbol-id="icons--cancel"
                        @click="searchValue = ''" />
                </template>
            </v-text-field>
            <v-btn class="d-none" v-on="on" />
        </template>
        <v-list class="gst-search-city-menu__list">
            <template v-if="canFindMyLocation">
                <v-list-item key="gps" @click="findMyLocation">
                    <v-list-item-icon class="mr-2">
                        <BaseIcon class="gst-search-city-menu__gps-icon"
                            symbol-id="icons--gps" />
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $t('_common:terms.useMyCurrentLocation') }}
                    </v-list-item-title>
                </v-list-item>
                <v-divider class="mx-4" />
            </template>
            <template v-if="showFilteredCities">
                <DataLoading v-if="!!loading" :width="2" class="gst-search-city-menu__loading" />
                <template v-else>
                    <SectionTitleSmall class="px-4 pt-4">
                        {{ $t('labels.results') }}
                    </SectionTitleSmall>
                    <SearchResultNoData v-if="!cities.length">
                        {{ $t('labels.noResults') }}
                    </SearchResultNoData>
                    <SearchCityList
                        class="px-2"
                        :items="cities"
                        :highlight="searchValue"
                        @select="selectCity">
                        <template v-slot:icon>
                            <v-list-item-icon class="mr-2">
                                <BaseIcon 
                                    class="gst-search-city-menu__item-icon"
                                    symbol-id="icons--location2" />
                            </v-list-item-icon>
                        </template>
                    </SearchCityList>
                </template>
            </template>
        </v-list>
    </v-menu>
</template>

<script>
    import { mapActions } from 'vuex';
    import throttle from 'lodash/throttle';
    import debounce from 'lodash/debounce';
    import {
        hasLocation as cityObjectUtilsHasLocation,
        hasCurrentLocation as cityObjectUtilsHasCurrentLocation
    } from '@core/utils/cityObjectUtils';
    import DataLoading from '@core/shared/components/loading/DataLoading.vue';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import SearchResultNoData from './common/SearchResultNoData.vue';
    import SectionTitleSmall from '../misc/SectionTitleSmall.vue';
    import SearchCityList from './SearchCityList.vue';

    const STORE_NAME = 'cities';
    const CONFIG = {
        MAX_RESULTS: 5
    };

    export default {
        name: 'SearchCity',
        components: {
            DataLoading,
            SectionTitleSmall,
            SearchResultNoData,
            SearchCityList,
            BaseIcon
        },
        i18nOptions: {
            namespaces: 'shared',
            keyPrefix: 'components.search.searchCity'
        },
        props: {
            value: {
                type: Object,
                default: ( ) => { return { name: '' }; }
            },
            inputSearchAttrs: {
                type: Object,
                default: () => {
                    return {
                        'single-line': true,
                        solo: true,
                        'hide-details': true,
                        flat: true
                    };
                }
            },
            clearable: {
                type: Boolean,
                default: true
            }
        },
        data( ) {
            return {
                searchValue: '',
                isSearching: false,
                isFocused: false,
                isMenuOpen: false,
                menuTargetPositionX: 0,
                menuTargetPositionY: 0,
                loadingCurrentLocation: false,
                cities: [],
                loading: 0
            };
        },
        computed: {
            showRecentCities( ) {
                return !this.isSearching;
            },
            showFilteredCities( ) {
                return this.isSearching;
            },
            canFindMyLocation( ) {
                return !!navigator.geolocation;
            },
            selectValueLabel( ) {
                const { value } = this;
                const cityLabel = this.$options.filters.city( value, { country: true } );

                if ( cityLabel ) {
                    return cityLabel;
                }

                if ( cityObjectUtilsHasLocation ( value ) ) {
                    if ( cityObjectUtilsHasCurrentLocation( value ) ) {
                        return this.$t( '_common:terms.myCurrentLocation' );
                    } else {
                        return this.$t( '_common:terms.location' );
                    }
                }
                return this.$t( 'placeholder' );
            }
        },
        watch: {
            'searchValue': function ( newValue ) {
                if ( !newValue ) {
                    this.$emit( 'input', { name: '' } );
                }
            },
            'value': function ( newValue ) {
                this.searchValue = newValue.name || '';
                this.isSearching = false;
                !this.unwatchSearchValPropAfterValueChange || this.unwatchSearchValPropAfterValueChange( );
                this.unwatchSearchValPropAfterValueChange = this.$watch( 'searchValue', ( newVal ) => {
                    if( newVal ) {
                        this.isSearching = true;
                        this.unwatchSearchValPropAfterValueChange( );
                    }
                } );
            },
        },
        methods: {
            ...mapActions( {
                searchCities: `${STORE_NAME}/searchList`,
                getCurrentLocation: 'user/location/getCurrentLocation',
                notificationError: 'notification/error',
            } ),
            async findMyLocation( ) {
                this.loadingCurrentLocation = true;
                const location = await this.getCurrentLocation( { showMessage: true, refresh: true } );
                if ( location && location.position ) {
                    this.selectCity( {
                        ...location.city,
                        location: {
                            current: true,
                            ...location.position
                        }
                    } );
                }
                this.loadingCurrentLocation = false;
            },
            selectCity( city ) {
                this.isSearching = false;
                this.$emit( 'input', city );
                this.isMenuOpen = false;
            },
            onInputInputSearch( value ) {
                this.searchValue = value;
                this.openMenu( );
            },
            onBlurInputSearch( ) {
                setTimeout( ( ) => {
                    this.searchValue = this.value.name || '';
                    this.isFocused = false;
                    this.closeMenu( );
                }, 200 );
            },
            onFocusInputSearch( ) {
                this.isFocused = true;
            },
            toggleMenu( ) {
                this.isMenuOpen ? this.closeMenu( ) : this.openMenu( );
            },
            closeMenu( ) {
                this.isMenuOpen = false;
            },
            openMenu( ) {
                const menuTargetPosition = this.$refs.inputSearch.$el.getBoundingClientRect( );

                this.menuTargetPositionX = menuTargetPosition.x;
                this.menuTargetPositionY = menuTargetPosition.y + menuTargetPosition.height;
                this.isMenuOpen = true;
            },
            loadDataDebounced: debounce( async function ( ) {
                this.loading = this.loading + 1;
                this.cities = await this.searchCities( { refresh: true, search: { keyword: this.searchValue, limit: CONFIG.MAX_RESULTS, offset: 0 } } ) || [ ];
                this.loading = this.loading - 1;
            }, 500 ),
            onTabKeyDo() {
                this.$emit( 'tab-keydown' );
            },
        },
        created( ) {
            this.searchValue = this.value.name;
            this.$watch( 'searchValue', ( newVal ) => {
                if( newVal && newVal !== this.value.name ) {
                    this.isSearching = true;
                    this.loadDataDebounced( );
                }
            } );
        },
        mounted( ) {
            this.throttleMenuClose = throttle( ( ) => {
                this.isMenuOpen = false;
            }, 20 );

            window.addEventListener( 'resize', this.throttleMenuClose );
        },
        destroyed( ) {
            window.removeEventListener( 'resize', this.throttleMenuClose );
        }
    };
</script>

<style lang="scss" scoped>
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-search-city-menu {
        background-color: theme-color( 'white' );
    }

    .gst-search-city-menu__loading {
        padding: theme-spacing( 4 );
    }

    .gst-search-city-menu__list {
        padding-top: theme-spacing( 0 );
        padding-bottom: theme-spacing( 0 );
        min-width: 280px;

        .v-list-item__title {
            font-size: $base-font-size !important;
        }

        .v-list-item__icon {
            margin-right: theme-spacing( 4 );
        }
    }

    .gst-search-city-menu__gps-icon {
        ::v-deep .gst-svg-icon {
            fill: theme-color( 'new-primary' );
        }
    }

    .gst-search-icon-svg-focused {
        ::v-deep .gst-svg-icon {
            fill: theme-color( 'new-primary' );
        }
    }
</style>
