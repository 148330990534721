<template>
    <div class="gst-search-info-steps d-flex flex-column flex-md-row flex-md-center">
        <div v-for="( item, index ) in items" :key="index" class="gst-search-info-steps__item d-flex flex-row align-center">
            <i>{{ item.index }}</i>
            <h5>{{ item.label }}</h5>
            <BaseIcon v-if="index < items.length - 1 && $vuetify.breakpoint.mdAndUp" symbol-id="icons--chevron_right" />
        </div>
    </div>
</template>

<script>
    import i18next from 'i18next';
    import { mapGetters } from 'vuex';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import SearchConstants from '@tenant/app/utils/constants/search';

    export default {
        name: 'SearchInfoSteps',
        components: {
            BaseIcon
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.home.searchProducts'
        },
        props: {
            type: {
                type: String,
                default: SearchConstants.TYPES.EVENTS
            }
        },

        computed: {
            ...mapGetters( {
                appLocale: 'appState/getLocale'
            } ),
            items() {
                const lngResource = i18next.getResource( this.appLocale, this._i18nOptions.namespaces[ 0 ], `${this._i18nOptions.keyPrefix}.${this.type}.infoSteps` );

                if ( lngResource ) {
                    return Object.entries( lngResource )
                        .map( item => ( { index: item[ 0 ], label: item[ 1 ] } ) );
                }

                return [ ];
            }
        }
    };
</script>