<template>
    <div class="gst-hero-wrapper">
        <section class="gst-hero">
            <video poster="/assets/hero_video_frame_1.png"
                class="video"
                autoplay
                muted
                loop
                playsinline>
                <source v-if="video.mp4" :src="video.mp4" type="video/mp4" />
                <source v-if="video.ogv" :src="video.ogv" type="video/ogv" />
                <source v-if="video.webm" :src="video.webm" type="video/webm" />
                Your browser doesn't support HTML5 video.
            </video>
            <div class="gst-hero__content d-flex align-end">
                <div class="gst-default-content">
                    <h1>{{ $t('title') }}</h1>
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <h3 v-html="$t('subtitle', { interpolation: { escapeValue: false } })"></h3>
                </div>
            </div>
        </section>
        <SearchProductsVue />
    </div>
</template>

<script>
    import SearchProductsVue from './SearchProducts.vue';

    export default {
        name: 'TheHero',
        components: {
            SearchProductsVue
        },
        i18nOptions:{
            namespaces: 'main',
            keyPrefix: 'views.home.theHome'
        },
        props: {
            video: {
                type: Object,
                default: ( ) => ( { } )
            }
        }
    };
</script>