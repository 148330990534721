<template>
    <div class="gst-home">
        <TheHeader
            :class="{
                'gst-header--bg-transparent': pageYOffset < 200
            }"
            @menu-opened="menuOpened = true" />
        <component :is="mobileMenuComponent" v-if="menuOpened" right @close="menuOpened = false" />
        <TheHero :video="videoTheHero" />
        <DataLoading v-if="loading" class="mx-5 px-5" />
        <template v-else>
            <div class="slicezone">
                <SliceZone
                    :slices="content.data.body"
                    :context="context"
                    :components="{
                        booking_info: BookingInfo,
                        top_offers: TopOffers,
                        top_locations: TopLocations,
                        offer_types: OfferTypes,
                        faq: Faq,
                        gallery_carousel: GalleryCarousel
                    }" />
            </div>
        </template>
        <TheFooter />
    </div>
</template>

<script>
    import throttle from 'lodash/throttle';
    import { mapGetters } from 'vuex';
    import { LogService } from '@core/services';
    import DataLoading from '@core/shared/components/loading/DataLoading.vue';
    import TheFooter from '@tenant/app/layouts/components/TheFooter.vue';
    import TheHeader from './_components/TheHeader.vue';
    import TheHero from './_components/TheHero.vue';
    import BookingInfo from './slices/BookingInfo.vue';
    import TopOffers from './slices/TopOffers.vue';
    import TopLocations from './slices/TopLocations.vue';
    import OfferTypes from './slices/OfferTypes.vue';
    import Faq from './slices/Faq.vue';
    import GalleryCarousel from './slices/GalleryCarousel.vue';

    export default {
        name: 'Home',
        components: {
            DataLoading,
            TheHero,
            TheHeader,
            TheFooter
        },
        data( ) {
            return {
                content: null,
                loading: true,
                menuOpened: false,
                pageYOffset: 0,
                BookingInfo,
                TopOffers,
                TopLocations,
                OfferTypes,
                Faq,
                GalleryCarousel
            };
        },
        computed: {
            ...mapGetters( {
                appLocale: 'appState/getLocale'
            } ),
            routeLang( ) {
                return this.$route.query.lang;
            },
            context( ) {
                return { };
            },
            videoTheHero( ) {
                const data = this.content?.data;

                if ( data ) {
                    return {
                        mp4: data.video_mp4?.url,
                        ogv: data.video_ogv?.url,
                        webm: data.video_webm?.url,
                    };
                }
                return { };
            },
            mobileMenuComponent( ) {
                return ( ) => import ( './_components/MobileMenu.vue' );
            }
        },
        watch: {
            appLocale: function ( newValue, oldValue ) {
                if ( newValue && newValue !== oldValue ) {
                    this.init( );
                }
            }
        },
        methods: {
            async init( ) {
                this.loading = true;
                this.content = await this.loadContent( this.appLocale );
                if ( !this.content && this.routeLang ) {
                    //fallback to master locale
                    this.content = await this.loadContent( );
                }
                this.loading = false;
                this.$nextTick( ( ) => {
                    this.scrollToHashAfterLoading( );
                } );
            },
            async loadContent( lang ) {
                const config = lang ? { lang } : { };

                try {
                    return await this.$prismic.client.getSingle(
                        'ticketmaster_home_page',
                        config
                    );
                } catch( e ) {
                    LogService.log( e.message );
                }
            },
            onWindowScrollDoThrottle: throttle( function ( ) {
                this.pageYOffset = window.pageYOffset;
            }, 200 ),
            scrollToHashAfterLoading( ) {
                const hash = window.location.hash;
                if ( hash ) {
                    this.$router.replace( { ...this.$route, hash: '' } );
                    this.$router.replace( { ...this.$route, hash } );
                }
            }
        },
        async mounted( ) {
            this.init( );
        },
        created( ) {
            window.addEventListener( 'scroll', this.onWindowScrollDoThrottle );
        },
        destroyed( ) {
            window.removeEventListener( 'scroll', this.onWindowScrollDoThrottle );
        }
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

$theme-color-primary: #024DDF;
$theme-color-secondary: #21FFF2;
$theme-color-tertiary: #646464;
$theme-color-septenary: #BFBFBF;
$theme-color-quaternary: #121212;
$theme-color-quinary: #F6F6F6;
$theme-color-accent: #FBFF2C;

.gst-home {
    width: 100%;
    background-color: $theme-color-quaternary;
    color: theme-color( 'white' );
    font-family: "Averta Std", sans-serif !important;
    min-height: calc( var( --vh, 1vh ) * 100 ) !important;

    h1 {
        line-height: 40px;
        font-size: 32px;
        font-weight: 800;
    }

    h2 {
        line-height: 28px;
        font-size: 24px;
        font-weight: 700;
    }

    h3 {
        line-height: 28px;
        font-size: 20px;
        font-weight: 600;
    }

    h4 {
        line-height: 22px;
        font-size: 18px;
        font-weight: 600;
    }

    h5 {
        line-height: 17px;
        font-size: 14px;
        font-weight: 400;
    }

    h6 {
        line-height: 16px;
        font-size: 13px;
        font-weight: 400;
    }

    p {
        line-height: 19px;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
    }

    a {
        line-height: 19px;
        color: $theme-color-secondary;
        font-size: 16px;
        font-weight: 400;
    }

    p.extra-text {
        line-height: 22px;
        font-size: 18px;
        font-weight: 700;
    }

    button {
        line-height: 20px;
        color: theme-color( 'white' );
        font-size: 16px;
        font-weight: 600;
    }

    p.subheader {
        line-height: 22px;
        font-size: 18px;
        font-weight: 400;
    }

    section {
        .gst-picture-base {
            border-radius: 2px;
            box-shadow: 0 0 0 0 rgba( 0, 0, 0, 0.44 ), 0 8px 18px 0 rgba( 0, 0, 0, 0.43 ), 0 33px 33px 0 rgba( 0, 0, 0, 0.37 ), 0 74px 44px 0 rgba( 0, 0, 0, 0.22 ), 0 131px 52px 0 rgba( 0, 0, 0, 0.06 ), 0 203.99998px 57px 0 rgba( 0, 0, 0, 0.01 );
        }
    }

    @include desktop-only {
        h1 {
            line-height: 74px;
            font-size: 60px;
            font-weight: 800;
        }

        h2 {
            line-height: 40px;
            font-size: 32px;
            font-weight: 700;
        }

        h3 {
            line-height: 28px;
            font-size: 24px;
            font-weight: 700;
        }

        h4 {
            line-height: 25px;
            font-size: 20px;
            font-weight: 600;
        }

        h5 {
            line-height: 17px;
            font-size: 14px;
            font-weight: 400;
        }

        h6 {
            line-height: 16px;
            font-size: 13px;
            font-weight: 400;
        }

        p {
            line-height: 19px;
            font-size: 16px;
            font-weight: 400;
        }

        a {
            line-height: 19px;
            color: $theme-color-secondary;
            font-size: 16px;
            font-weight: 400;
        }

        p.extra-text {
            line-height: 17px;
            font-size: 14px;
            font-weight: 600;
        }

        button {
            line-height: 20px;
            color: theme-color( 'white' );
            font-size: 16px;
            font-weight: 600;
        }

        p.chip-text {
            line-height: 15px;
            font-size: 12px;
            font-weight: 700;
        }
    }

    .gst-header {
        display: flex;
        position: fixed;
        height: 60px;
        width: 100%;
        padding: 13px 16px;
        background: linear-gradient( 180deg, $theme-color-quaternary 0%, rgba( $theme-color-quaternary, 0 ) 123.33% );
        background-color: rgba( $theme-color-quaternary, 1 );
        transition: all 0.5s ease;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;
        z-index: $z-index-header;

        a {
            color: theme-color( 'white' );
            font-size: 14px;
        }

        .gst-svg-icon {
            fill: theme-color( 'white' );
        }

        .gst-header__links-wrapper {
            align-items: center;

            a {
                margin-left: 24px;
                align-items: center;
            }
        }
    }

    .gst-header.gst-header--bg-transparent {
        background-color: rgba( $theme-color-quaternary, 0 );
    }

    .gst-menu {
        width: 100% !important;
        background-color: theme-color( 'white' );

        h4 {
            color: theme-color( 'quaternary' );
            font-weight: 700;
        }

        h5 {
            color: theme-color( 'quaternary' );
            font-weight: 700;
        }

        .gst-icon-chevron-left {
            transform: rotate( 90deg );
        }

        .gst-icon-chevron-left .gst-svg-icon {
            fill: theme-color( 'primary' );
        }

        .gst-menu__links-wrapper {
            margin-top: 79px;
        }

        .gst-menu__header {
            align-items: center;
            justify-content: space-between;

            .gst-menu__invisible-div {
                height: 36px;
                width: 36px;
            }
        }

        .gst-menu__search-link-wrapper {
            align-items: center;
        }

        .gst-svg-icon {
            fill: theme-color( 'quaternary' );
        }

        .gst-menu__language-selector {
            position: absolute;
            left: 0;
            bottom: 128px;
            width: 100%;
        }

        .gst-menu__footer {
            position: absolute;
            left: 0;
            bottom: 0;
            height: 128px;
            width: 100%;
            background-color: theme-color( 'quinary' );
            justify-content: center;

            h5 {
                font-weight: 400;
            }
        }
    }

    .gst-default-content {
        margin: 0 auto;
        max-width: 1232px;
    }

    .gst-hero-wrapper {
        @include viewport-height( 99 );

        display: flex;
        width: 100%;
        min-height: 770px;
        max-height: 1000px;
        flex-direction: column;

        @media (max-width: 300px) {
            height: unset;
            min-height: unset;
            max-height: unset;

            .gst-hero {
                height: 600px;
            }
        }

        @include desktop-only {
            height: 98vh;
            min-height: 600px;
            max-height: 1100px;
        }

        .gst-hero {
            display: flex;
            position: relative;
            width: 100%;
            padding-right: 4.26%;
            padding-left: 4.26%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            flex-grow: 1;

            .video {
                position: absolute;
                top: 0;
                left: 0;
                height: 99%;
                width: 100%;
                object-fit: cover;
            }

            .gst-hero__content {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 32px;
                padding-bottom: 60px;
                background: linear-gradient( 180deg, rgba( $theme-color-quaternary, 0 ) 25.52%, $theme-color-quaternary 95.66% );

                h3 {
                    margin-top: 32px;

                    span {
                        color: $theme-color-accent;
                    }
                }
            }

            @include desktop-only {
                height: 820px;
            }
        }

        .gst-search-products {
            width: 100%;
            padding: 0 16px;

            .gst-search-products--search-field {
                .gst-location-search-dropdown {
                    width: 250px;

                    .gst-location-search-dropdown__input-wrapper {
                        height: 46px;
                        width: 100%;
                    }

                    .gst-location-search-dropdown__input {
                        height: 46px;
                        padding-right: 0;
                    }

                    .gst-location-search-dropdown__selection {
                        line-height: 46px;
                    }
                }
            }

            .gst-search-products__tabs {
                margin-bottom: 32px;

                .v-tabs-bar {
                    height: 64px;

                    .v-slide-group__prev,
                    .v-slide-group__next {
                        display: none !important;
                    }

                    .v-tab {
                        padding: 0  8px;
                        color: theme-color( 'white' ) !important;
                        caret-color: theme-color( 'white' ) !important;
                        border-bottom: 1px solid rgba( $theme-color-tertiary, 0.5 );

                        span {
                            font-size: 16px;
                            font-weight: 600;
                            text-transform: capitalize;
                        }

                        //we need a wrapper to fix https://github.com/vuetifyjs/vuetify/issues/4733
                        //svg is loaded after the 1st renderer
                        .gst-tabs__icon-wrapper {
                            height: 24px;
                            width: 24px;
                            margin-right: 8px;

                            svg {
                                height: 100%;
                                width: 100%;

                                .gst-svg-icon {
                                    fill: theme-color( 'white' );
                                }
                            }
                        }
                    }
                }

                .v-tabs-slider-wrapper {
                    height: 4px !important;
                }
            }

            .gst-search-products__title {
                margin-bottom: 20px;
            }

            .gst-search-products-field {
                height: 62px;
                width: 100%;
                padding: 8px 8px 8px 24px;
                background-color: theme-color( 'white' );
                min-height: 50px;
                border-radius: 4px;
                box-shadow: 0 0 10 10 rgba( theme-color-hex( 'white' ), 0.25 );

                @include desktop-only {
                    width: 976px;
                }
            }

            .gst-search-products-field.gst-search-products-field--events,
            .gst-search-products-field.gst-search-products-field--hotels {
                .icon-search {
                    height: 20px;
                    width: 20px;

                    .gst-svg-icon {
                        fill: theme-color( 'new-tertiary' );
                    }
                }

                input {
                    padding: theme-spacing( 2 );
                    color: theme-color( 'new-tertiary' ) !important;
                    font-size: 16px !important;
                }
            }

            .gst-search-products-field.gst-search-products-field--events {
                .gst-search-events-input {
                    width: inherit;
                    
                    .u-mouse-pointer {
                        width: inherit;

                        input {
                            width: inherit;
                            text-overflow: ellipsis !important;
                        }
                    }

                    .icon-clear {
                        .gst-svg-icon {
                            fill: theme-color( 'tertiary' );
                        }
                    }

                    &.gst-search-events-input--focus {
                        .icon-search {
                            .gst-svg-icon {
                                fill: theme-color( 'primary' );
                            }
                        }
                    }
                }

                input {
                    width: inherit;
                    text-overflow: ellipsis !important;
                }
            }

            .gst-search-products-field.gst-search-products-field--hotels {
                input {
                    width: inherit;
                    text-overflow: ellipsis !important;
                }

                .gst-location-search-dropdown {
                    .gst-location-search-dropdown__selection--placeholder {
                        color: theme-color( 'tertiary' );
                    }

                    svg {
                        height: 20px;
                        width: 20px;
                        margin-left: 0 !important;

                        .gst-svg-icon {
                            fill: theme-color( 'tertiary' );
                        }
                    }

                    .form-input-focus {
                        input {
                            padding: 8px;
                        }

                        svg {
                            .gst-svg-icon {
                                fill: theme-color( 'primary' );
                            }
                        }
                    }
                }

                .gst-search-date__input {
                    svg {
                        .gst-svg-icon {
                            fill: theme-color( 'tertiary' );
                        }
                    }
                }

                .gst-search-date__input.v-input--is-focused {
                    svg {
                        .gst-svg-icon {
                            fill: theme-color( 'primary' );
                        }
                    }
                }
            }

            .gst-search-info-steps {
                padding: 0 16px;
                margin-top: 32px;

                .gst-search-info-steps__item {
                    margin-bottom: 16px;
                }

                h5 {
                    font-weight: 600;
                }

                i {
                    width: 24px;
                    line-height: 24px;
                    margin-right: 8px;
                    color: $theme-color-quaternary;
                    font-size: 14px;
                    font-weight: 600;
                    border-radius: 50%;
                    text-align: center;
                }

                svg {
                    margin: 0 8px;
                }
            }

            &.gst-search-products--event-active {
                .gst-search-products__tabs {
                    .v-tabs-slider-wrapper {
                        color: $theme-color-secondary !important;
                    }
                }

                .gst-search-info-steps {
                    i {
                        background-color: $theme-color-secondary;
                    }
                }
            }

            &.gst-search-products--hotel-active {
                .gst-search-products__tabs {
                    .v-tabs-slider-wrapper {
                        color: $theme-color-accent !important;
                    }
                }

                .gst-search-info-steps {
                    i {
                        background-color: $theme-color-accent;
                    }
                }
            }

            @include desktop-only {
                padding: 0;
                margin-top: 0;

                .gst-search-products__tabs {
                    margin-bottom: 32px;

                    .v-tabs-bar {
                        .v-tab {
                            span {
                                font-size: 20px;
                                font-weight: 600;
                            }

                            .gst-tabs__icon-wrapper {
                                height: 31px;
                                width: 29px;
                                margin-right: 16px;
                            }
                        }
                    }
                }

                .gst-search-info-steps {
                    margin-top: 40px;

                    .gst-search-info-steps__item {
                        margin-bottom: unset;
                    }

                    h5 {
                        font-size: 20px;
                    }

                    i {
                        width: 40px;
                        line-height: 40px;
                        font-size: 20px;
                        font-weight: 600;
                    }

                    svg {
                        display: block;
                    }
                }
            }
        }
    }

    .gst-top-locations {
        padding: 32px 0 16px;

        .gst-top-locations__header {
            display: flex;
            margin: 0 16px 32px;
            flex-direction: column;
            text-align: center;
            gap: 12px;

            p {
                margin-bottom: 0;
            }
        }

        .gst-top-locations__card_container {
            display: flex;
            height: 201px;
            padding: 0 16px;
            gap: 16px;
            overflow: auto;
        }

        .gst-top-locations__button_container {
            margin-top: 24px;
            text-align: center;
        }

        .gst-top-locations__find_button {
            background-color: $theme-color-primary !important;
            color: white;
            font-size: 16px;
        }

        @include desktop-only {
            .gst-top-locations__card_container {
                height: 800px;
                width: 1024px;
                padding: 24px 40px 160px;
                margin: 0 auto;
                justify-content: center;
                flex-wrap: wrap;
            }

            .gst-top-locations__item {
                max-width: 304px !important;
            }

            .gst-top-locations__button_container {
                position: relative;
                bottom: 144px;
            }
        }
    }

    .gst-offer-types {
        padding: 80px 0;

        .gst-offer-types__content {
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            gap: 24px;
        }

        .gst-offer-types__list {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .gst-offer-types__list__item {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 30px;

                svg {
                    height: 25px;
                    width: 25px;
                }
            }
        }

        .gst-offer-types__text {
            text-align: center;
        }

        @include desktop-only {
            .gst-offer-types__list {
                padding: 0 72px 40px;

                .gst-offer-types__list__item {
                    svg {
                        height: 40px;
                        width: 40px;
                    }
                }
            }
        }
    }

    .gst-slice-home-faq {
        margin: 104px auto;
        max-width: 922px;

        .gst-slice-dropdown__row {
            margin: theme-spacing( 2 ) 0;
        }

        .gst-slice-dropdown__title {
            width: 100%;
            padding: theme-spacing( 2 ) theme-spacing( 2 ) 0 !important;

            p {
                margin-bottom: 0;
            }
        }

        .gst-slice-dropdown__content {
            padding-top: theme-spacing( 1 );
            color: $theme-color-septenary;

            p {
                margin-bottom: 0;
            }
        }

        .gst-svg-icon {
            fill: $theme-color-secondary;
        }

        @include mobile-only {
            padding: 0 16px;
        }
    }

    .gst-slice-gallery-carousel {
        .gst-image-scroll-gallery {
            display: flex;
            width: 100%;
            margin-bottom: 12px;
            max-width: 100vw;
            flex-direction: column;
            overflow: hidden;

            .gst-image-scroll-gallery__container {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                margin-left: 12px;

                .gst-image-scroll-gallery__section {
                    display: flex;
                    gap: 12px;

                    .v-image {
                        height: 150px;
                        width: 200px;
                    }
                }
            }
        }

        @include desktop-only {
            .gst-image-scroll-gallery {
                margin-bottom: 16px;

                .gst-image-scroll-gallery__container {
                    margin-left: 16px;

                    .gst-image-scroll-gallery__section {
                        height: auto;
                        gap: 16px;

                        .v-image {
                            height: 230px;
                            width: 432px;
                        }
                    }
                }
            }
        }

        @include desktop-xl-only {
            .gst-image-scroll-gallery .gst-image-scroll-gallery__container .gst-image-scroll-gallery__section {
                .v-image {
                    height: 400px;
                    width: 772px;
                }
            }
        }
    }

    .gst-booking-info {
        position: relative;
        padding: 69px 0;
        overflow: hidden;
        z-index: $z-index-home-section;

        .gst-booking-info__wrapper {
            padding-top: theme-spacing( 6 );
            margin: 0 auto;
            max-width: 944px;
            gap: theme-spacing( 4 );
        }

        .gst-booking-info__content {
            padding: theme-spacing( 8, 6 );
            background: rgba( 18, 18, 18, 0.5 );

            p {
                margin: 0;
            }
        }

        .gst-slice__section-subtitle {
            color: $theme-color-septenary;
        }

        .gst-booking-info__section {
            flex-direction: column;
            gap: theme-spacing( 5 );
            z-index: 2;
        }

        .gst-booking-info__section:nth-child(2) {
            padding-top: theme-spacing( 8 );
            flex-direction: column-reverse;
            justify-content: flex-end;
        }

        .gst-base-icon {
            height: 52px;
            width: 52px;

            &.icon-bed {
                .gst-svg-icon {
                    fill: $theme-color-secondary;
                }
            }

            &.icon-ticket {
                .gst-svg-icon {
                    fill: $theme-color-accent;
                }
            }
        }

        @include mobile-only {
            padding: 80px 0 45px;

            .gst-base-icon {
                height: 34px;
                width: 34px;
            }

            .gst-booking-info__section:nth-child(2) {
                padding-top: 0;
            }

            .gst-booking-info__content {
                margin: 0 12px;
            }

            .gst-slice__title {
                margin: 0 12px;
            }
        }
    }

    .gst-slice-top-offers {
        position: relative;
        background: transparent !important;
        z-index: $z-index-home-section;

        .gst-slice-top-offers--wrapper {
            padding-top: 69px;
            padding-bottom: 49px;
            margin: 0 auto;
        }

        .gst-row--col__translate:nth-of-type(3n-1) {
            translate: 0 98px;
        }

        .gst-card-base {
            height: 428px;
            margin: 0 auto;
            background: transparent;
        }

        .gst-picture-base {
            border-radius: 2px;
            box-shadow: 0 0 0 0 rgba( 0, 0, 0, 0.44 ), 0 8px 18px 0 rgba( 0, 0, 0, 0.43 ), 0 33px 33px 0 rgba( 0, 0, 0, 0.37 ), 0 74px 44px 0 rgba( 0, 0, 0, 0.22 ), 0 131px 52px 0 rgba( 0, 0, 0, 0.06 ), 0 203.99998px 57px 0 rgba( 0, 0, 0, 0.01 );
        }

        .gst-slice-show-more-button {
            height: 46px;
            border-radius: 50px;

            .gst-svg-icon {
                fill: theme-color( 'white' );
            }
        }

        @include mobile-xss-only {
            .row {
                .col-12 {
                    padding: 0;
                }
            }
        }

        @include desktop-only {
            .gst-slice-top-offers--wrapper {
                max-width: 720px;
            }
        }

        @include desktop-lg-only {
            .gst-slice-top-offers--wrapper {
                padding: 82px 0;
                max-width: 1280px;

                .gst-slice-picture-cards-grid {
                    padding-bottom: 138px;
                }
            }
        }
    }
}

.gst-floating-manage-cookies-button#ot-sdk-btn.ot-sdk-show-settings {
    display: none;
}
</style>