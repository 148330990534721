var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gst-home"},[_c('TheHeader',{class:{
            'gst-header--bg-transparent': _vm.pageYOffset < 200
        },on:{"menu-opened":function($event){_vm.menuOpened = true}}}),(_vm.menuOpened)?_c(_vm.mobileMenuComponent,{tag:"component",attrs:{"right":""},on:{"close":function($event){_vm.menuOpened = false}}}):_vm._e(),_c('TheHero',{attrs:{"video":_vm.videoTheHero}}),(_vm.loading)?_c('DataLoading',{staticClass:"mx-5 px-5"}):[_c('div',{staticClass:"slicezone"},[_c('SliceZone',{attrs:{"slices":_vm.content.data.body,"context":_vm.context,"components":{
                    booking_info: _vm.BookingInfo,
                    top_offers: _vm.TopOffers,
                    top_locations: _vm.TopLocations,
                    offer_types: _vm.OfferTypes,
                    faq: _vm.Faq,
                    gallery_carousel: _vm.GalleryCarousel
                }}})],1)],_c('TheFooter')],2)}
var staticRenderFns = []

export { render, staticRenderFns }