<template>
    <BaseSlice v-bind="navigationAttributes" class="gst-slice-gallery-carousel">
        <ImageScrollGallery :items="firstGroupOfPhotos" :scroll-speed="slice.primary.scroll_speed" :animation-time="slice.primary.animation_time" />
        <ImageScrollGallery :items="secondGroupOfPhotos" :scroll-speed="slice.primary.scroll_speed" :animation-time="slice.primary.animation_time" :reversed="true" />
    </BaseSlice>
</template>

<script>
    import ImageScrollGallery from '@tenants/ticketmaster/app/views/home/_components/ImageScrollGallery.vue';
    import BaseSlice from '@tenants/ticketmaster/app/slices/components/BaseSlice.vue';
    import NavMixin from '@tenants/ticketmaster/app/slices/mixins/NavMixin.js';

    export default {
        name: 'GalleryCarousel',
        components: {
            BaseSlice,
            ImageScrollGallery
        },
        mixins: [ NavMixin ],
        props: {
            slice: {
                type: Object,
                required: true
            },
            context: {
                type: Object,
                required: true
            }
        },
        computed: {
            firstGroupOfPhotos() {
                return this.slice.items.filter( ( item, index ) => index < ( this.slice.items.length  / 2 ) );
            },
            secondGroupOfPhotos() {
                return this.slice.items.filter( ( item, index ) => index >= ( this.slice.items.length  / 2 ) );
            }
        }
    };
</script>
