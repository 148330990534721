<template>
    <div class="gst-background-base">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'BackgroundBase'
    };
</script>

<style lang="scss" scoped>
@import "@scssVariables";
@import "@scssMixins";

.gst-background-base {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: theme-color( 'new-quaternary' );
    overflow: hidden;
    z-index: $z-index-background-animation;
}

</style>