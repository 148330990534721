<template>
    <BaseSlice id="faq" v-bind="navigationAttributes" class="gst-slice-home-faq">
        <PrismicRichText :field="slice.primary.title" class="gst-slice__title text-center" />
        <DropdownList :items="slice.items" :separator="false" />
    </BaseSlice>
</template>

<script>
    import NavMixin from '@tenants/ticketmaster/app/slices/mixins/NavMixin.js';
    import BaseSlice from '@tenants/ticketmaster/app/slices/components/BaseSlice.vue';
    import DropdownList from '@tenants/ticketmaster/app/slices/components/DropdownList.vue';

    export default {
        name: 'Faq',
        components: {
            DropdownList,
            BaseSlice
        },
        mixins: [ NavMixin ],
        props: {
            slice: {
                type: Object,
                default: null
            }
        }
    };
</script>