<template>
    <div
        class="gst-search-products-field d-flex align-center"
        :class="{
            'gst-search-products-field--events': isEventsTabActive,
            'gst-search-products-field--hotels': isHotelsTabActive
        }"
        tabindex="0"
        @click="onClickDo"
        @keypress.enter="onClickDo">
        <template v-if="isEventsTabActive">
            <SearchEvents v-if="!isMobile" />
            <template v-else>
                <BaseIcon symbol-id="icons--search" class="icon-search" />
                <BaseInput
                    id="search.events"
                    :data-aria-label="$t('events.search.placeholder')"
                    data-test-id="search.events"
                    class="flex-grow-1"
                    :placeholder="$t('events.search.placeholder')"
                    readonly />
            </template>
        </template>
        <template v-if="isHotelsTabActive">
            <SearchHotelsInput v-if="!isMobile" />
            <template v-else>
                <BaseIcon symbol-id="icons--search" class="icon-search" />
                <BaseInput
                    id="search.hotels"
                    :data-aria-label="$t('hotels.search.placeholder')"
                    data-test-id="search.hotels"
                    class="flex-grow-1"
                    :placeholder="$t('hotels.search.placeholder')"
                    readonly />
            </template>
        </template>
    </div>
</template>

<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import BaseInput from '@core/shared/components/input/BaseInput.vue';
    import SearchConstants from '@tenant/app/utils/constants/search';
    import SearchEvents from './SearchEvents';
    import SearchHotelsInput from './SearchHotels/SearchHotelsInput';

    export default {
        name: 'SearchField',
        components: {
            SearchEvents,
            SearchHotelsInput,
            BaseIcon,
            BaseInput
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.home.searchProducts'
        },
        props: {
            type: {
                type: String,
                default: SearchConstants.TYPES.EVENTS
            }
        },
        computed: {
            isEventsTabActive( ) {
                return this.type === SearchConstants.TYPES.EVENTS;
            },
            isHotelsTabActive( ) {
                return this.type === SearchConstants.TYPES.HOTELS;
            },
            isMobile( ) {
                return  this.$vuetify.breakpoint.mdAndDown;
            }
        },
        methods: {
            onClickDo( e ) {
                if ( this.isMobile ) {
                    e.preventDefault( );
                    e.stopPropagation( );
                    this.$modal.showBottom(
                        ( ) => import ( './SearchModal' ),
                        {
                            type: this.type
                        },
                        {
                            'no-click-animation': true,
                            scrollable: true,
                            fullscreen: false,
                            'hide-overlay': false,
                            'content-class': 'v-dialog__height-90 gst-v-dialog',
                        }
                    );
                }
            }
        }
    };
</script>