
<template>
    <div class="gst-search-events-list">
        <DataLoading v-if="loading" :width="2" />
        <div v-else>
            <div
                v-if="attractions.length"
                class="py-2">
                <h5 class="px-4 py-2">
                    {{ $t('headers.attractions') }}
                </h5>
                <v-list-item
                    v-for="(attraction, index) in attractions.slice( 0, 5 )"
                    :key="index"
                    dense
                    @click="onClickAttractionDo( attraction )">
                    {{ attraction.name }}
                </v-list-item>
            </div>
            <hr v-if="attractions.length" class="gst-search-events-list__divider mx-4" />
            <div
                v-if="events.length"
                class="py-2">
                <h5 class="px-4 py-2">
                    {{ $t('headers.events') }}
                </h5>
                <v-list-item
                    v-for="(event, index) in events.slice( 0, 5 )"
                    :key="index"
                    @click="onClickEventDo( event )">
                    <EventCardVariant1
                        :with-price-icon="true"
                        :event="event"
                        v-bind="eventCardBind"
                        class="u-background-transparent u-width-100 py-2" />
                </v-list-item>
            </div>
            <hr v-if="events.length" class="gst-search-events-list__divider mx-4" />
            <div
                v-if="venues.length"
                class="py-2">
                <h5 class="px-4 py-2">
                    {{ $t('headers.venues') }}
                </h5>
                <v-list-item
                    v-for="(venue, index) in venues.slice( 0, 5 )"
                    :key="index"
                    @click="onClickVenueDo( venue )">
                    <VenueCardSmall
                        :venue="venue"
                        class="u-background-transparent u-width-100 py-2" />
                </v-list-item>
            </div>
            <div v-if="hasSearchResults" class="d-flex justify-center pa-4">
                <SeeAllButton @click="onClickSeeAllDo" />
            </div>
            <SearchResultNoData v-if="showNoData">
                {{ $t('messages.listEmpty' ) }}
            </SearchResultNoData>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';
    import DataLoading from '@core/shared/components/loading/DataLoading.vue';
    import SearchResultNoData from '@tenant/app/components/search/common/SearchResultNoData.vue';
    import SeeAllButton from '@tenant/app/components/buttons/SeeAllButton.vue';
    import VenueCardSmall from '@tenant/app/components/venue/VenueCardSmall';
    import EventCardVariant1 from './EventCardVariant1.vue';

    export default {
        name: 'SearchEventsList',
        components: {
            DataLoading,
            EventCardVariant1,
            VenueCardSmall,
            SearchResultNoData,
            SeeAllButton
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.home.searchProducts.events.search'
        },
        props: {
            searchKeywordOnly: {
                type: Boolean,
                default: false
            },
            redirectToAttraction: {
                type: Boolean,
                default: true
            },
            redirectToEvent: {
                type: Boolean,
                default: true
            },
            redirectToVenue: {
                type: Boolean,
                default: true
            },
            seeAllButtonRoute: {
                type: Object,
                default: null
            },
            eventCardBind: {
                type: Object,
                default: ( ) => ( { contentMaxLines: 1 } )
            }
        },
        computed: {
            ...mapState( {
                events:             state => state.searchEvents.results.events,
                attractions:        state => state.searchEvents.results.attractions,
                venues:             state => state.searchEvents.results.venues
            } ),
            ...mapGetters( {
                loading: 'searchEvents/loading',
                currentQueryObject: 'searchEvents/currentQueryObject',
                isOnlyKeyword: 'searchEvents/isOnlyKeyword',
                isEmptyFilter: 'searchEvents/isEmptyFilter',
                hasSearchResults: 'searchEvents/hasResults'
            } ),
            showNoData( ) {
                return !this.events.length && !this.attractions.length && !this.venues.length && !this.isEmptyFilter;
            }
        },
        methods: {
            onClickSeeAllDo( ) {
                const routeName = this.isOnlyKeyword ? 'search' : 'search.events';
                const route = this.$router.resolve( { name: routeName, query: this.currentQueryObject } );
                window.open( route.href, '_blank' );
            },
            onClickEventDo( event ) {
                if ( this.redirectToEvent ) {
                    const route = this.$router.resolve( { name: 'event', params: { id: event.id } } );

                    window.open( route.href, '_blank' );
                } else {
                    this.$emit( 'select', event.name );
                }
            },
            onClickAttractionDo( item ) {
                if ( this.redirectToAttraction ) {
                    const route = this.$router.resolve( { name: 'attraction', params: { id: item.id } } );

                    window.open( route.href, '_blank' );
                } else {
                    this.$emit( 'select', item.name );
                }
            },
            onClickVenueDo( item ) {
                if ( this.redirectToVenue ) {
                    const route = this.$router.resolve( { name: 'venue', params: { id: item.id } } );

                    window.open( route.href, '_blank' );
                } else {
                    this.$emit( 'select', item.name );
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-search-events-list {
        background-color: theme-color( 'white' );

        h5 {
            color: theme-color( 'new-tertiary' );
            font-size: 14px;
            font-weight: 600;
        }

        ::v-deep .gst-venue-card__title {
            font-size: 14px;
            font-weight: 400;
        }

        .gst-search-events-list__divider {
            border: 1px solid theme-color( 'senary' );
        }
    }
</style>
