<template>
    <section class="gst-top-locations">
        <div class="gst-top-locations__header">
            <PrismicRichText :field="slice.primary.title" />
            <PrismicRichText :field="slice.primary.subtitle" />
        </div>
        <div class="gst-top-locations__card_container">
            <CardBase
                v-for="(item, key) in slice.items"
                :key="key"
                class="gst-top-locations__item"
                max-width="204">
                <template>
                    <a v-if="item.cta_link.url" :href="item.cta_link.url" :target="item.cta_link.target ? item.cta_link.target : '_blank'">
                        <PictureBase :src="item.image.url || ''" :alt="item.image.url || ''" />
                    </a>
                    <PictureBase v-else :src="item.image.url || ''" :alt="item.image.url || ''" />
                </template>
            </CardBase>
        </div>
        <div class="gst-top-locations__button_container">
            <BaseButton
                v-if="buttonPlaceholder"
                class="gst-top-locations__find_button"
                :placeholder="buttonPlaceholder"
                :to="routeHotelsTab" />
        </div>
    </section>
</template>

<script>
    import get from 'lodash/get';
    import BaseButton from '@core/shared/components/buttons/BaseButton.vue';
    import SearchConstants from '@tenant/app/utils/constants/search';
    import CardBase from '../_components/CardBase.vue';
    import PictureBase from '../_components/PictureBase.vue';

    export default {
        name: 'TopLocations',
        components: {
            BaseButton,
            CardBase,
            PictureBase
        },
        props: {
            slice: {
                type: Object,
                required: true
            },
            context: {
                type: Object,
                required: true
            }
        },
        computed: {
            buttonPlaceholder() {
                return get( this.slice, 'primary.button_label' ) || '';
            },
            routeHotelsTab( ) {
                return {
                    name: 'home',
                    query: { searchType: SearchConstants.TYPES.HOTELS },
                    hash: '#search-products'
                };
            }
        }
    };
</script>
