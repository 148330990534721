<template>
    <section
        id="search-products"
        class="gst-search-products gst-default-content d-flex flex-column align-center"
        :class="{
            'gst-search-products--event-active': isEventTabActive,
            'gst-search-products--hotel-active': isHotelTabActive,
        }">
        <v-tabs
            ref="tabs"
            v-model="activeTab"
            background-color="transparent"
            color="secondary"
            class="gst-search-products__tabs d-flex justify-center"
            :show-arrows="false">
            <v-tab
                v-for="tab in tabs"
                :key="tab.id"
                :tabindex="tab.id">
                <div class="gst-tabs__icon-wrapper">
                    <BaseIcon :symbol-id="tab.icon" :class="tab.icon" />
                </div>
                <span>{{ tab.title }}</span>
            </v-tab>
        </v-tabs>
        <p class="gst-search-products__title text-center">
            {{ title }}
        </p>
        <SearchField class="gst-search-products--search-field" :type="activeType" />
        <SearchInfoSteps :type="activeType" />
    </section>
</template>

<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import SearchConstants from '@tenant/app/utils/constants/search';
    import SearchField from './SearchProducts/SearchField.vue';
    import SearchInfoSteps from './SearchProducts/SearchInfoSteps.vue';
    import TabMixin from './SearchProducts/TabsMixin';

    export default {
        name: 'SearchProducts',
        components: {
            SearchField,
            SearchInfoSteps,
            BaseIcon
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.home.searchProducts'
        },
        mixins: [
            TabMixin
        ],
        computed: {
            title( ) {
                return this.$t( `${this.activeType}.title` );
            }
        },
        watch: {
            '$route.query.searchType': {
                handler: function ( value ) {
                    if ( value ) {
                        if ( value === SearchConstants.TYPES.EVENTS ) {
                            this.activeTab = 0;
                        } else {
                            this.activeTab = 1;
                        }
                    }
                },
                immediate: true
            },
            'activeTab': {
                handler: function ( value ) {
                    if ( value === 0 ) {
                        this.$router.replace( { name: 'home', query: { searchType: 'events' } } );
                    } else {
                        this.$router.replace( { name: 'home', query: { searchType: 'hotels' } } );
                    }
                }
            }
        },
    };
</script>
