<template>
    <div
        class="gst-background-gradient"
        :class="{ 'gst-background-gradient--bottom': position === 'bottom' }"
        :style="style"></div>
</template>

<script>
    export default {
        name: 'BackgroundGradient',
        props: {
            size: {
                type: Number,
                default: 200
            },
            position: {
                type: String,
                default: 'top',
                validator( value ) {
                    // The value must match one of these strings
                    return [ 'top', 'bottom' ].includes( value );
                }
            }
        },
        computed: {
            style() {
                const gradientStyle = {
                    height: `${this.size}px`
                };
                if ( this.position === 'top' ) {
                    gradientStyle.top = 0;
                } else {
                    gradientStyle.bottom = 0;
                }
                return gradientStyle;
            }
        }
    };
</script>

<style lang="scss" scoped>
@import "@scssVariables";
@import "@scssMixins";

.gst-background-gradient {
    position: absolute;
    height: 300px;
    width: 100%;
    background: linear-gradient( 0deg, rgba( 18, 18, 18, 0 ) 0%, theme-color( 'new-quaternary' ) 95% );
    z-index: 1;

    &.gst-background-gradient--bottom {
        background: linear-gradient( 180deg, rgba( 18, 18, 18, 0 ) 0%, theme-color( 'new-quaternary' ) 95% );
    }
}
</style>