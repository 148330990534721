<template>
    <BaseSlice :style="style" v-bind="navigationAttributes" class="gst-booking-info">
        <PrismicRichText :field="slice.primary.title" class="gst-slice__title text-center mb-md-10 mb-xs-4" />
        <div class="gst-booking-info__wrapper d-flex" :class="[isMobile && 'flex-column']">
            <div v-for="(item, index) in slice.items" :key="index" class="gst-booking-info__section d-flex">
                <div class="gst-booking-info__content">
                    <div class="d-flex justify-space-between">
                        <div class="d-flex flex-column mb-6">
                            <PrismicRichText :field="item.title" class="gst-slice__section-title" />
                            <PrismicRichText :field="item.subtitle" class="gst-slice__section-subtitle mt-2" />
                        </div>
                        <BaseIcon :symbol-id="index === 0 ? 'icons--ticket_bed': 'icons--bed' " :class="index === 0 ? 'icon-bed': 'icon-ticket'" />
                    </div>
                    <PrismicRichText :field="item.content" class="gst-slice__section-content" />
                </div>
                <PrismicImage v-show="!isMobile" :field="item.image" class="gst-slice__content--width-100" :width="imagesProps.width" :height="imagesProps.height" />
            </div>
        </div>
        <BookingInfoAnimation />
    </BaseSlice>
</template>

<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import BaseSlice from '@tenants/ticketmaster/app/slices/components/BaseSlice';
    import NavMixin from '@tenants/ticketmaster/app/slices/mixins/NavMixin.js';
    import BookingInfoAnimation from '../_components/BookingInfoAnimation.vue';
    
    export default {
        name: 'BookingInfo',
        components: {
            BaseSlice,
            BaseIcon,
            BookingInfoAnimation
        },
        mixins: [ NavMixin ],
        props: {
            slice: {
                type: Object,
                required: true
            },
            context: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                bg_image: {
                    type: String,
                    default: ''
                }
            };
        },
        computed: {
            isMobile( ) {
                return this.$vuetify.breakpoint.smAndDown;
            },
            imagesProps( ) {
                return {
                    width: 464,
                    height: 213
                };
            },
            style() {
                return {
                    background: `url(${this.slice.primary.bg_image.url})`,
                    backgroundSize: 'cover'
                };
            }
        }
    };
</script>
