<template>
    <div>
        <BaseSlice :style="style" v-bind="navigationAttributes" class="gst-slice-top-offers">
            <div class="gst-slice-top-offers--wrapper">
                <PrismicRichText :field="slice.primary.title" class="d-flex justify-center mb-8" />
                <PictureCardsGridBase :items="displayedItems" :number-of-cols="numberOfCols" class="gst-slice-picture-cards-grid" />
                <div v-if="slice.primary.show_more_btn && areMoreItemsConfiguredThanDefault" class="d-flex justify-center align-center mt-1">
                    <BaseButton
                        color="white"
                        class="gst-slice-show-more-button px-10 py-4"
                        outlined
                        @click="toggleItemsDisplay()">
                        {{ placeholder }}
                        <BaseIcon
                            symbol-id="icons--dropdown_arrow"
                            :class="{'u-rotate-180' : !toggleShowingMoreOrLess }"
                            class="u-mouse-pointer toggle-down ml-2" />
                    </BaseButton>
                </div>
            </div>
            <TopOffersAnimation class="gst-slice-top-offers__background" />
        </BaseSlice>
    </div>
</template>

<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import NavMixin from '@tenants/ticketmaster/app/slices/mixins/NavMixin.js';
    import BaseSlice from '@tenants/ticketmaster/app/slices/components/BaseSlice.vue';
    import PictureCardsGridBase from '../_components/PictureCardsGridBase.vue';
    import TopOffersAnimation from '../_components/TopOffersAnimation.vue';

    export default {
        name: 'TopOffers',
        components: {
            BaseSlice,
            PictureCardsGridBase,
            BaseIcon,
            BaseButton,
            TopOffersAnimation
        },
        mixins: [ NavMixin ],
        props: {
            slice: {
                type: Object,
                required: true
            },
            context: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                displayedItems: [],
            };
        },
        computed: {
            style() {
                return '';
            },
            numberOfCols() {
                if ( this.$vuetify.breakpoint.lgAndUp ) {
                    return '4';
                }
                return this.$vuetify.breakpoint.mdAndUp ? '6' : '12';
            },
            placeholder() {
                if ( this.toggleShowingMoreOrLess ) {
                    return this.$t( '_common:buttons:showMore' );
                }
                return this.$t( '_common:buttons:showLess' );
            },
            currentDeviceConfig() {
                if ( this.$vuetify.breakpoint.mdAndUp ) {
                    return {
                        defaultItems: 12,
                        operationalNumber: 6,
                        maxItems: 18,
                    };
                } else {
                    return {
                        defaultItems: 6,
                        operationalNumber: 3,
                        maxItems: 12,
                    };
                }
            },
            toggleShowingMoreOrLess() {
                return !( this.displayedItems.length >= this.slice.items.length );
            },
            areMoreItemsConfiguredThanDefault() {
                return  this.slice.items.length > this.currentDeviceConfig.defaultItems;
            },
        },
        watch: {
            '$vuetify.breakpoint.name': {
                immediate: true,
                handler() {
                    this.resetDefaultItems();
                }
            },
        },
        methods: {
            toggleItemsDisplay() {
                if ( this.toggleShowingMoreOrLess ) {
                    this.showMoreItems();
                } else {
                    this.resetDefaultItems();
                }
            },
            resetDefaultItems() {
                this.displayedItems = this.slice.items.slice( 0, this.currentDeviceConfig.defaultItems );
            },
            resetToMaxItems() {
                this.displayedItems = this.slice.items.slice( 0, this.currentDeviceConfig.maxItems );
            },
            showMoreItems() {
                const endIndex = this.displayedItems.length + this.currentDeviceConfig.operationalNumber;
                this.displayedItems = this.slice.items.slice( 0, endIndex );
                this.toggleShowingMoreOrLess;
            },
        },
        mounted() {
            if( this.slice.primary.show_more_btn ) {
                this.resetDefaultItems();
            } else {
                this.resetToMaxItems();
            }
        }
    };
</script>

<style lang="scss" scoped>
    .gst-slice-top-offers__background {
        margin-top: 200px;
    }
</style>
